// import React from "react";
import { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from '../utils/apiCalling';
import { useLocation } from "react-router-dom";
import Loader from '../utils/loader';
import * as XLSX from 'xlsx';

const UploadExcel = () => {
    const [loader, setLoader] = useState()
    const path = useLocation();
    const upload = (e) => {
        // console.log(e.target.result, "uuuuuuuuuuu")
        let f=e.target.files[0]
        // var name = f.name;
        const reader = new FileReader();
    //    let evt=e.tar.get.files
    // console.log(f)
        reader.onload = (e) => { // evt = on_file_select event
            /* Parse data */
            const bstr = e.target.result;
            console.log(bstr,"kkkkkkkkkkk")
            const wb = XLSX.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            /* Update state */
            // console.log("Data>>>" + data);
        };
        reader.readAsBinaryString(f);
    }
    return (
        <main className='bg-lgrey position-relative container-fluid p-0'>
            {loader && <Loader />}
            <section id="all-section" className="admin-header position-relative">

                <nav className="navbar navbar-expand-lg bg-white p-0 border border-bottom">
                    <div className="container-fluid mt-2 mb-2">
                        <a className="navbar-brand1" ><img src={(path.pathname === '/IDFC/Agency' || path.pathname === '/IDFC/Yard') ? "../images/idfc-first-bank-logo.jpg" : "../images/avantiFinancelogo.png"} width="100px" alt="" /></a>

                        <ul className="navbar-nav flex-row align-items-center justify-contents-center">

                        </ul>
                    </div></nav>
            </section>
            <section className='h-100 survey-screen container-fluid'>
                <div className='row justify-content-center'>
                    <div className='col-md-9 mb-5'>
                        <div className='survey-questions bg-white p-3 my-3 com-bsl w-100 mb-5'>
                            {/* <div className='company-name w-100 mb-3'><img src='../images/logo (3).png' className=''></img></div> */}
                            <div className='row justify-content-center' >
                                <div className='con col-md-6'>
                                    <input type='file' accept='.csv, .xlsx' onChange={(e) => upload(e)} />
                                    <button className='py-2 mainbtn'>Upload</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}
export default UploadExcel;